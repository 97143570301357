import { ChakraProvider, Spinner, Center } from "@chakra-ui/react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { lazy, Suspense } from "react";
import { theme } from "./utils/theme";

// Lazy load pages
const Login = lazy(() => import("./pages/Login"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Bhandaras = lazy(() => import("./pages/Bhandaras"));
const Banners = lazy(() => import("./pages/Banners"));
const FeatureFlags = lazy(() => import("./pages/FeatureFlags"));

// Layouts
import DashboardLayout from "./layouts/DashboardLayout";

// Auth components
import ProtectedRoute from "./components/ProtectedRoute";

// Loading fallback component
const LoadingFallback = () => (
  <Center h="100vh" w="100vw">
    <Spinner size="xl" color="brand.500" thickness="4px" />
  </Center>
);

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Suspense fallback={<LoadingFallback />}>
          <Routes>
            {/* Public routes */}
            <Route path="/login" element={<Login />} />

            {/* Protected routes */}
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <DashboardLayout>
                    <Dashboard />
                  </DashboardLayout>
                </ProtectedRoute>
              }
            />

            <Route
              path="/bhandaras"
              element={
                <ProtectedRoute>
                  <DashboardLayout>
                    <Bhandaras />
                  </DashboardLayout>
                </ProtectedRoute>
              }
            />

            <Route
              path="/banners"
              element={
                <ProtectedRoute>
                  <DashboardLayout>
                    <Banners />
                  </DashboardLayout>
                </ProtectedRoute>
              }
            />

            <Route
              path="/features"
              element={
                <ProtectedRoute>
                  <DashboardLayout>
                    <FeatureFlags />
                  </DashboardLayout>
                </ProtectedRoute>
              }
            />

            {/* Redirect any unknown routes to dashboard */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Suspense>
      </Router>
    </ChakraProvider>
  );
}

export default App;
