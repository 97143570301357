import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App.jsx'

// Disable React strict mode in development to avoid double API calls
const isProduction = import.meta.env.PROD

createRoot(document.getElementById('root')).render(
  isProduction ? (
    <StrictMode>
      <App />
    </StrictMode>
  ) : (
    <App />
  )
)
