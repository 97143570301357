import { create } from "zustand";
import { persist } from "zustand/middleware";
import { authService } from "../api/authService";

/**
 * Custom error class for authentication errors
 */
export class AuthError extends Error {
  constructor(code, message) {
    super(message);
    this.name = "AuthError";
    this.code = code;
  }
}

// Create auth store with persistence
const useAuthStore = create(
  persist(
    (set) => ({
      // Initial state
      user: null,
      token: null,
      refreshToken: null,
      isAuthenticated: false,
      isLoading: false,
      error: null,

      // Actions
      login: async (credentials) => {
        set({ isLoading: true, error: null });
        try {
          const { user, token, refreshToken } = await authService.login(credentials);

          // Check if user has admin role
          if (user.role !== "admin") {
            throw new AuthError(
              "ADMIN_ONLY",
              "Access denied. Only administrators are allowed to login."
            );
          }

          set({
            user,
            token,
            refreshToken,
            isAuthenticated: true,
            isLoading: false,
            error: null,
          });

          return user;
        } catch (error) {
          const errorMessage =
            error.message || "Failed to login. Please check your credentials.";
          set({ isLoading: false, error: errorMessage });
          throw error;
        }
      },

      logout: async () => {
        try {
          await authService.logout();
        } finally {
          set({
            user: null,
            token: null,
            refreshToken: null,
            isAuthenticated: false,
            error: null,
          });
        }
      },

      updateUser: (userData) =>
        set((state) => ({
          user: { ...state.user, ...userData },
        })),

      checkAuth: () => {
        // Simply check if token exists in localStorage
        const token = localStorage.getItem("auth_token");
        const isAuthenticated = !!token;

        // Update state based on token existence
        if (!isAuthenticated) {
          set({ user: null, token: null, isAuthenticated: false });
        }

        return isAuthenticated;
      },

      clearError: () => set({ error: null }),

      // Update tokens after refresh
      updateTokens: ({ token, refreshToken }) => {
        set({
          token,
          refreshToken,
          isAuthenticated: true,
        });
      },
    }),
    {
      name: "auth-storage", // Name for the localStorage key
      getStorage: () => localStorage, // Use localStorage for persistence
      partialize: (state) => ({
        user: state.user,
        token: state.token,
        refreshToken: state.refreshToken,
        isAuthenticated: state.isAuthenticated,
      }),
    }
  )
);

export default useAuthStore;
