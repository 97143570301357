import axios from 'axios';
import { authService } from './authService';

export class ApiError extends Error {
  constructor(
    status,
    message,
    data = null,
    code = null
  ) {
    super(message);
    this.name = 'ApiError';
    this.status = status;
    this.data = data;
    this.code = code;
  }
}

class ApiClient {
  constructor() {
    this.client = axios.create({
      baseURL: import.meta.env.VITE_API_URL || '/api',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.setupInterceptors();
  }

  setupInterceptors() {
    // Request interceptor
    this.client.interceptors.request.use(
      (config) => {
        // Get token from localStorage
        const token = localStorage.getItem('auth_token');
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    
    // Create a flag to prevent multiple refresh attempts
    let isRefreshing = false;
    // Store pending requests that should be retried after token refresh
    let failedQueue = [];

    const processQueue = (error, token = null) => {
      failedQueue.forEach(prom => {
        if (error) {
          prom.reject(error);
        } else {
          prom.resolve(token);
        }
      });
      
      failedQueue = [];
    };

    // Response interceptor
    this.client.interceptors.response.use(
      (response) => response.data,
      async (error) => {
        const originalRequest = error.config;
        
        // Check if the error is due to an expired token (401) and the request hasn't been retried yet
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
          // If the request was for token refresh, don't try to refresh again
          if (originalRequest.url === '/api/v2/admin/auth/refresh-token') {
            // Clear tokens and redirect to login
            localStorage.removeItem('auth_token');
            localStorage.removeItem('refresh_token');
            window.location.href = '/login';
            return Promise.reject(error);
          }
          
          if (isRefreshing) {
            // If a refresh is already in progress, add this request to the queue
            return new Promise((resolve, reject) => {
              failedQueue.push({ resolve, reject });
            })
              .then(token => {
                originalRequest.headers['Authorization'] = `Bearer ${token}`;
                return this.client(originalRequest);
              })
              .catch(err => {
                return Promise.reject(err);
              });
          }

          // Mark the request as retried to prevent infinite loops
          originalRequest._retry = true;
          isRefreshing = true;

          // Try to refresh the token
          try {
            // Get the new access token from the refresh response
            // The refresh token is automatically stored in localStorage by authService.refreshToken()
            const { accessToken } = await authService.refreshToken();
            
            // Update the authorization header for the original request
            originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
            
            // Process any queued requests with the new token
            processQueue(null, accessToken);
            
            // Reset refreshing flag
            isRefreshing = false;
            
            // Retry the original request with the new token
            return this.client(originalRequest);
          } catch (refreshError) {
            // If refresh fails, process queue with error and redirect to login
            processQueue(refreshError, null);
            isRefreshing = false;
            
            // Clear tokens and redirect to login
            localStorage.removeItem('auth_token');
            localStorage.removeItem('refresh_token');
            window.location.href = '/login';
            return Promise.reject(refreshError);
          }
        }
        
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const status = error.response.status;
          const data = error.response.data;
          const message = data?.message || error.message;

          // Handle specific status codes
          switch (status) {
            case 403:
              // Handle forbidden access
              break;
            case 404:
              // Handle not found
              break;
            case 422:
              // Handle validation errors
              break;
            case 500:
              // Handle server errors
              break;
          }

          throw new ApiError(status, message, data, data?.code);
        } else if (error.request) {
          // The request was made but no response was received
          throw new ApiError(0, 'Network Error: No response received');
        } else {
          // Something happened in setting up the request that triggered an Error
          throw new ApiError(0, error.message || 'Unknown Error');
        }
      }
    );
  }

  // HTTP method wrappers - simplified to only what we need
  async get(url, config) {
    try {
      const response = await this.client.get(url, config);
      return response;
    } catch (error) {
      if (error instanceof ApiError) {
        throw error;
      }
      throw new ApiError(0, 'Unknown Error');
    }
  }

  async post(url, data, config) {
    try {
      const response = await this.client.post(url, data, config);
      return response;
    } catch (error) {
      if (error instanceof ApiError) {
        throw error;
      }
      throw new ApiError(0, 'Unknown Error');
    }
  }

  async patch(url, data, config) {
    try {
      const response = await this.client.patch(url, data, config);
      return response;
    } catch (error) {
      if (error instanceof ApiError) {
        throw error;
      }
      throw new ApiError(0, 'Unknown Error');
    }
  }

  async put(url, data, config) {
    try {
      const response = await this.client.put(url, data, config);
      return response;
    } catch (error) {
      if (error instanceof ApiError) {
        throw error;
      }
      throw new ApiError(0, 'Unknown Error');
    }
  }

  async delete(url, config) {
    try {
      const response = await this.client.delete(url, config);
      return response;
    } catch (error) {
      if (error instanceof ApiError) {
        throw error;
      }
      throw new ApiError(0, 'Unknown Error');
    }
  }
}

export const apiClient = new ApiClient();
