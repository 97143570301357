import { extendTheme } from '@chakra-ui/react'

// Define the color palette based on Bhandara branding
const colors = {
  brand: {
    50: '#fff4e0',
    100: '#ffe4b3',
    200: '#ffd480',
    300: '#ffc44d',
    400: '#ffb41a',
    500: '#ff9900', // Primary brand color (as specified)
    600: '#cc7a00',
    700: '#995c00',
    800: '#663d00',
    900: '#331f00',
  },
  accent: {
    50: '#fff5e6',
    100: '#ffeacc',
    200: '#ffd699',
    300: '#ffc266',
    400: '#ffad33',
    500: '#ff9900', // Secondary brand color (orange from Bhandara logo)
    600: '#cc7a00',
    700: '#995c00',
    800: '#663d00',
    900: '#331f00',
  }
}

// Define the global styles
const styles = {
  global: {
    body: {
      bg: 'gray.50',
      color: 'gray.800',
    },
  },
}

// Define the component styles
const components = {
  Button: {
    baseStyle: {
      fontWeight: 'semibold',
      borderRadius: 'md',
    },
    variants: {
      solid: {
        bg: 'brand.500',
        color: 'white',
        _hover: {
          bg: 'brand.600',
        },
      },
      outline: {
        border: '1px solid',
        borderColor: 'brand.500',
        color: 'brand.500',
      },
    },
  },
  Heading: {
    baseStyle: {
      fontWeight: 'bold',
      color: 'gray.800',
    },
  },
}

// Export the theme
export const theme = extendTheme({
  colors,
  styles,
  components,
  fonts: {
    heading: 'Inter, system-ui, sans-serif',
    body: 'Inter, system-ui, sans-serif',
  },
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
})
