import {
  Box,
  Flex,
  IconButton,
  Image,
  Text,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  HStack,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  useColorModeValue,
  Divider,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  HamburgerIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import SidebarContent from "../components/SidebarContent";
import useAuthStore from "../store/useAuthStore";

const DashboardLayout = ({ children }) => {
  const { isOpen, onOpen, onClose: originalOnClose } = useDisclosure();
  const [shouldRenderDrawer, setShouldRenderDrawer] = useState(false);
  const { user } = useAuthStore();
  const navigate = useNavigate();
  
  // Custom onClose function that delays the removal of the drawer
  const onClose = () => {
    originalOnClose();
    // Keep the drawer in DOM for 500ms to allow animation to complete
    // Chakra's default animation duration is 200ms
    setTimeout(() => {
      setShouldRenderDrawer(false);
    }, 300);
  };
  
  // Update shouldRenderDrawer when isOpen changes
  useEffect(() => {
    if (isOpen) {
      setShouldRenderDrawer(true);
    }
  }, [isOpen]);

  const handleLogout = () => {
    // Clear auth token
    localStorage.removeItem("auth_token");
    // Redirect to login page
    navigate("/login");
  };

  // Colors
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const sidebarBg = useColorModeValue("gray.50", "gray.900");

  return (
    <Flex h="100vh" w="100vw" flexDirection="column">
      {/* Header */}
      <Flex
        as="header"
        align="center"
        justify="space-between"
        w="full"
        px={4}
        bg={bgColor}
        borderBottomWidth="1px"
        borderColor={borderColor}
        h="16"
        boxShadow="sm"
      >
        <Flex align="center">
          {/* Mobile menu button */}
          <IconButton
            display={{ base: "flex", lg: "none" }}
            onClick={onOpen}
            variant="outline"
            aria-label="open menu"
            icon={<HamburgerIcon />}
            mr={2}
          />

          {/* Logo */}
          <Image
            src="/images/logo.png"
            alt="Bhandara Logo"
            boxSize="40px"
            fallbackSrc="https://via.placeholder.com/40"
          />
          <Text
            ml={3}
            fontSize="xl"
            fontWeight="bold"
            display={{ base: "none", md: "flex" }}
          >
            Bhandara Admin
          </Text>
        </Flex>

        {/* User menu */}
        <Menu>
          <MenuButton
            as={HStack}
            spacing={3}
            cursor="pointer"
            p={0.5}
            borderRadius="md"
            _active={{}}
            _focus={{}}
            _hover={{}}
          >
            <Avatar size="sm" name={user?.fullname} background={"orange.400"} />
            {/* <Text display={{ base: "none", md: "flex" }}>Admin</Text> */}
            <ChevronDownIcon />
          </MenuButton>
          <MenuList>
            {/* <MenuItem>Profile</MenuItem>
            <MenuItem>Settings</MenuItem> */}
            <Divider />
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </MenuList>
        </Menu>
      </Flex>

      <Flex flex={1} overflow="hidden">
        {/* Desktop Sidebar */}
        <Box
          as="aside"
          w={64}
          bg={sidebarBg}
          borderRightWidth="1px"
          borderColor={borderColor}
          display={{ base: "none", lg: "block" }}
          overflowY="auto"
        >
          <SidebarContent />
        </Box>

        {/* Mobile Sidebar Drawer - Only render when needed */}
        {shouldRenderDrawer && (
          <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader borderBottomWidth="1px">
              <Flex align="center">
                <Image
                  src="/images/logo.png"
                  alt="Bhandara Logo"
                  boxSize="30px"
                  fallbackSrc="https://via.placeholder.com/30"
                />
                <Text ml={2} fontWeight="bold">
                  Bhandara Admin
                </Text>
              </Flex>
            </DrawerHeader>
            <DrawerBody p={0}>
              <SidebarContent onClick={onClose} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        )}

        {/* Main Content */}
        <Box
          flex={1}
          p={4}
          overflowY="auto"
          bg={useColorModeValue("gray.50", "gray.900")}
          w="full"
        >
          {children}
        </Box>
      </Flex>
    </Flex>
  );
};

export default DashboardLayout;
