import { Icon } from "@chakra-ui/react";

/**
 * Custom Feature Flag Icon Component
 * Provides a visual representation of a feature flag
 */
const FeatureFlagIcon = (props) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M12,8H4V6H12V8M12,10H4V12H12V10M12,14H4V16H12V14M15,16V6H21V16H15M17,8V14H19V8H17Z"
      />
    </Icon>
  );
};

export default FeatureFlagIcon;
