import { NavLink as RouterLink } from "react-router-dom";
import {
  Box,
  Flex,
  Icon,
  Text,
  VStack,
  HStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { ChevronRightIcon, ChevronDownIcon } from "@chakra-ui/icons";
import {
  FiHome,
  FiUsers,
  FiSettings,
  FiFileText,
  FiGrid,
  FiMessageSquare,
  FiDollarSign,
  FiBell,
  FiDatabase,
  FiCreditCard,
} from "react-icons/fi";
import FeatureFlagIcon from "./icons/FeatureFlagIcon";

// Navigation items
const navItems = [
  { name: "Dashboard", icon: FiHome, path: "/" },
  { name: "Bhandaras", icon: FiDatabase, path: "/bhandaras" },
  { name: "Banners", icon: FiCreditCard, path: "/banners" },
  { name: "Feature Flags", icon: FeatureFlagIcon, path: "/features" },
];

const SidebarContent = ({ onClick }) => {
  // const [activeItem, setActiveItem] = useState(null);

  // Colors
  const activeBg = useColorModeValue("brand.400");
  const activeColor = useColorModeValue("black");
  const inactiveColor = useColorModeValue("gray.600");
  const hoverBg = useColorModeValue("brand.400");

  return (
    <VStack align="stretch" spacing={1} py={5}>
      {navItems.map((item) => (
        <Box key={item.name}>
          <RouterLink
            to={item.path}
            style={{ textDecoration: "none" }}
            onClick={onClick}
          >
            {({ isActive }) => (
              <Flex
                align="center"
                p={3}
                mx={3}
                borderRadius="md"
                role="group"
                cursor="pointer"
                bg={isActive ? activeBg : "transparent"}
                color={isActive ? activeColor : inactiveColor}
                _hover={{
                  bg: hoverBg,
                  color: activeColor,
                }}
              >
                <Icon
                  mr={4}
                  fontSize="16"
                  as={item.icon}
                  _groupHover={{
                    color: activeColor,
                  }}
                />
                <Text fontSize="sm" fontWeight={isActive ? "medium" : "normal"}>
                  {item.name}
                </Text>
              </Flex>
            )}
          </RouterLink>
        </Box>
      ))}
    </VStack>
  );
};

export default SidebarContent;
