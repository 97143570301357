import { apiClient } from "./apiClient";
import useAuthStore from "../store/useAuthStore";

/**
 * User interface representing the authenticated user
 */
export class User {
  constructor(_id, email, fullname, role, active, photo = "", phone = "") {
    this._id = _id;
    this.email = email;
    this.fullname = fullname;
    this.role = role;
    this.active = active;
    this.photo = photo;
    this.phone = phone;
  }

  static fromJson(json) {
    return new User(
      json._id,
      json.email,
      json.fullname,
      json.role,
      json.active,
      json.photo,
      json.phone
    );
  }
}

/**
 * Authentication service for handling login, logout, and user management
 */
class AuthService {
  /**
   * Login a user with email and password
   * @param {Object} credentials - User credentials
   * @param {string} credentials.email - User email
   * @param {string} credentials.password - User password
   * @returns {Promise<{token: string, user: User}>} Authentication result with token and user
   */
  async login(credentials) {
    try {
      const response = await apiClient.post(
        "/api/v2/admin/auth/login",
        credentials
      );
      
      // Extract data from the new response structure
      const { accessToken, refreshToken, user } = response;

      // Store tokens in localStorage
      localStorage.setItem("auth_token", accessToken);
      localStorage.setItem("refresh_token", refreshToken);

      return {
        token: accessToken,
        refreshToken,
        user: User.fromJson(user),
      };
    } catch (error) {
      console.error("Login failed:", error);
      throw error;
    }
  }

  /**
   * Logout the current user
   */
  async logout() {
    try {
      // Optional: Call logout endpoint if your API has one
      // await apiClient.post('/api/v1/users/logout');
    } finally {
      // Always clear local storage
      localStorage.removeItem("auth_token");
      localStorage.removeItem("refresh_token");
    }
  }

  // We're not implementing getCurrentUser as we're not sure if this endpoint exists in the backend

  /**
   * Check if the user is authenticated
   * @returns {boolean} True if authenticated
   */
  isAuthenticated() {
    return !!localStorage.getItem("auth_token");
  }

  /**
   * Refresh the access token using the refresh token
   * @returns {Promise<{accessToken: string, refreshToken: string}>} New tokens
   */
  async refreshToken() {
    try {
      const refreshToken = localStorage.getItem("refresh_token");
      
      if (!refreshToken) {
        throw new Error("No refresh token available");
      }

      const response = await apiClient.post(
        "/api/v2/admin/auth/refresh-token",
        { refreshToken }
      );

      // Extract the new tokens from the response
      const { token: accessToken, refreshToken: newRefreshToken } = response;

      // Update both tokens in localStorage
      localStorage.setItem("auth_token", accessToken);
      localStorage.setItem("refresh_token", newRefreshToken);

      // Update the auth store state with the new tokens
      const updateTokens = useAuthStore.getState().updateTokens;
      updateTokens({
        token: accessToken,
        refreshToken: newRefreshToken
      });

      return { accessToken, refreshToken: newRefreshToken };
    } catch (error) {
      console.error("Token refresh failed:", error);
      // If refresh fails, log the user out
      this.logout();
      throw error;
    }
  }
}

export const authService = new AuthService();
