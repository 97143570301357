import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuthStore from '../store/useAuthStore';

/**
 * Protected route component that ensures only authenticated users can access certain routes
 * Redirects to login if not authenticated
 */
const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const { isAuthenticated, checkAuth } = useAuthStore();

  // Check authentication on component mount
  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  if (!isAuthenticated) {
    // Redirect to login page with the current location for redirect after login
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;
